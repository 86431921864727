@tailwind base;

@tailwind components;

@tailwind utilities;

*,
html,
body {
  font: 100% "EuH", arial, helvetica, sans-serif;
}

/* ------- E+H Styles ------- */

h1,
.h1 {
  font-family: "EuH Serif";
  font-size: 34px;
  line-height: 48px;
  font-weight: 400;
  margin: 40px 0;
}

h2,
.h2 {
  font-family: "EuH Serif";
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
  margin: 30px 0 10px 0;
}

h3,
.h3 {
  font-family: "EuH";
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  margin: 40px 0 20px 0;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-family: "EuH";
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  margin: 20px 0;
}

h1.is-blue,
h2.is-blue,
h3.is-blue,
h4.is-blue,
h5.is-blue,
h6.is-blue,
.h1.is-blue,
.h2.is-blue,
.h3.is-blue,
.h4.is-blue,
.h5.is-blue,
.h6.is-blue {
  color: #007caa;
}

p,
li,
dd,
dt,
table,
label,
input,
textarea,
blockquote {
  font-size: 18px;
  line-height: 25px;
  font-family: "EuH";
  font-weight: 200;
}

p + p {
  margin-top: 20px;
}

p + img {
  margin-top: 20px;
}

img + p {
  margin-top: 20px;
}

blockquote {
  padding: 20px;
  font-family: "EuH";
  font-weight: 400;
  font-style: italic;
  background: #e6ecf0;
}

table {
  width: 100%;
  padding: 10px;
  border-spacing: 0;
}
table thead {
  font-weight: 400;
  text-align: left;
}
table thead th {
  border-bottom: 1px solid #c3ced5;
  padding: 5px 5px 10px 5px;
}
table tbody {
  font-size: 90%;
}
table tbody tr td {
  padding: 5px;
}
table tbody tr:first-child td {
  padding: 10px 5px 5px 5px;
}
table tbody tr:last-child td {
  padding: 5px 5px 10px 5px;
}
table tfoot {
  font-size: 75%;
}
table tfoot td {
  border-top: 1px solid #c3ced5;
  padding: 10px 5px 5px 5px;
}

a {
  color: #a8005c;
  text-decoration: none;
  font-family: "EuH";
}

a:hover {
  color: #007caa;
}

p a {
  display: inline-block;
}

@media screen and (max-width: 640px) {
  .table-wrap {
    overflow-x: auto;
  }

  .table-wrap table {
    min-width: 500px;
    font-size: 14px;
  }
}

/*---- fonts ----*/

@font-face {
  font-family: "EuH";
  src: url("../fonts/EuH_SansBol.ttf") format("ttf"),
    url("../fonts/EuH_SansBol.woff") format("woff"),
    url("../fonts/EuH_SansBol.eot") format("eot");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "EuH";
  src: url("../fonts/EuH_SansBolIta.ttf") format("ttf"),
    url("../fonts/EuH_SansBolIta.woff") format("woff"),
    url("../fonts/EuH_SansBolIta.eot") format("eot");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "EuH";
  src: url("../fonts/EuH_SansDem.ttf") format("ttf"),
    url("../fonts/EuH_SansDem.woff") format("woff"),
    url("../fonts/EuH_SansDem.eot") format("eot");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "EuH";
  src: url("../fonts/EuH_SansDemIta.ttf") format("ttf"),
    url("../fonts/EuH_SansDemIta.woff") format("woff"),
    url("../fonts/EuH_SansDemIta.eot") format("eot");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "EuH Light";
  src: url("../fonts/EuH_SansLig.ttf") format("ttf"),
    url("../fonts/EuH_SansLig.woff") format("woff"),
    url("../fonts/EuH_SansLig.eot") format("eot");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "EuH";
  src: url("../fonts/EuH_SansLigIta.ttf") format("ttf"),
    url("../fonts/EuH_SansLigIta.woff") format("woff"),
    url("../fonts/EuH_SansLigIta.eot") format("eot");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "EuH Sans Regular";
  src: url("../fonts/EuH_SansReg.ttf") format("ttf"),
    url("../fonts/EuH_SansReg.woff") format("woff"),
    url("../fonts/EuH_SansReg.eot") format("eot");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuH";
  src: url("../fonts/EuH_SansRegIta.ttf") format("ttf"),
    url("../fonts/EuH_SansRegIta.woff") format("woff"),
    url("../fonts/EuH_SansRegIta.eot") format("eot");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "EuH Serif";
  src: url("../fonts/EuH_SerifBol.ttf") format("ttf"),
    url("../fonts/EuH_SerifBol.woff") format("woff"),
    url("../fonts/EuH_SerifBol.eot") format("eot");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "EuH Serif";
  src: url("../fonts/EuH_SerifBolIta.ttf") format("ttf"),
    url("../fonts/EuH_SerifBolIta.woff") format("woff"),
    url("../fonts/EuH_SerifBolIta.eot") format("eot");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "EuH Serif";
  src: url("../fonts/EuH_SerifDem.ttf") format("ttf"),
    url("../fonts/EuH_SerifDem.woff") format("woff"),
    url("../fonts/EuH_SerifDem.eot") format("eot");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "EuH Serif";
  src: url("../fonts/EuH_SerifDemIta.ttf") format("ttf"),
    url("../fonts/EuH_SerifDemIta.woff") format("woff"),
    url("../fonts/EuH_SerifDemIta.eot") format("eot");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "EuH Serif";
  src: url("../fonts/EuH_SerifLig.ttf") format("ttf"),
    url("../fonts/EuH_SerifLig.woff") format("woff"),
    url("../fonts/EuH_SerifLig.eot") format("eot");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "EuH Serif";
  src: url("../fonts/EuH_SerifLigIta.ttf") format("ttf"),
    url("../fonts/EuH_SerifLigIta.woff") format("woff"),
    url("../fonts/EuH_SerifLigIta.eot") format("eot");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "EuH Serif Regular";
  src: url("../fonts/EuH_SerifReg.ttf") format("ttf"),
    url("../fonts/EuH_SerifReg.woff") format("woff"),
    url("../fonts/EuH_SerifReg.eot") format("eot");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EuH Serif";
  src: url("../fonts/EuH_SerifRegIta.ttf") format("ttf"),
    url("../fonts/EuH_SerifRegIta.woff") format("woff"),
    url("../fonts/EuH_SerifRegIta.eot") format("eot");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "eh-iconset-20";
  src: url("../fonts/eh-iconset-20.eot");
  src: url("../fonts/eh-iconset-20.eot?#iefix") format("embedded-opentype"),
    url("../fonts/eh-iconset-20.woff") format("woff"),
    url("../fonts/eh-iconset-20.ttf") format("truetype"),
    url("../fonts/eh-iconset-20.svg#eh-iconset-20") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "eh-iconset-20" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[className^="icon-"]:before,
[className*=" icon-"]:before {
  font-family: "eh-iconset-20" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eh-zoom-out:before {
  content: "\61";
}

.icon-eh-zoom-in:before {
  content: "\62";
}

.icon-eh-youtube:before {
  content: "\63";
}

.icon-eh-youku:before {
  content: "\64";
}

.icon-eh-wm:before {
  content: "\65";
}

.icon-eh-wizard:before {
  content: "\66";
}

.icon-eh-wireless-no-connection:before {
  content: "\67";
}

.icon-eh-wireless-connection:before {
  content: "\68";
}

.icon-eh-wifi:before {
  content: "\69";
}

.icon-eh-wechat:before {
  content: "\6a";
}

.icon-eh-system:before {
  content: "\6b";
}

.icon-eh-team:before {
  content: "\6c";
}

.icon-eh-time:before {
  content: "\6d";
}

.icon-eh-tools:before {
  content: "\6e";
}

.icon-eh-twitter:before {
  content: "\6f";
}

.icon-eh-undo:before {
  content: "\70";
}

.icon-eh-uninstall:before {
  content: "\71";
}

.icon-eh-unlink:before {
  content: "\72";
}

.icon-eh-update:before {
  content: "\73";
}

.icon-eh-user:before {
  content: "\74";
}

.icon-eh-user-filled:before {
  content: "\75";
}

.icon-eh-visualisation:before {
  content: "\76";
}

.icon-eh-volume:before {
  content: "\77";
}

.icon-eh-resize:before {
  content: "\78";
}

.icon-eh-rfid:before {
  content: "\79";
}

.icon-eh-smiley-good:before {
  content: "\7a";
}

.icon-eh-smiley-ok:before {
  content: "\41";
}

.icon-eh-software:before {
  content: "\42";
}

.icon-eh-solutions:before {
  content: "\43";
}

.icon-eh-sort:before {
  content: "\44";
}

.icon-eh-sound:before {
  content: "\45";
}

.icon-eh-spinner:before {
  content: "\46";
}

.icon-eh-start-pause:before {
  content: "\47";
}

.icon-eh-step-error:before {
  content: "\48";
}

.icon-eh-stop:before {
  content: "\49";
}

.icon-eh-submenu:before {
  content: "\4a";
}

.icon-eh-support:before {
  content: "\4b";
}

.icon-eh-swipe:before {
  content: "\4c";
}

.icon-eh-smiley-bad:before {
  content: "\4d";
}

.icon-eh-skip:before {
  content: "\4e";
}

.icon-eh-sizing:before {
  content: "\4f";
}

.icon-eh-sina-weibo:before {
  content: "\50";
}

.icon-eh-show-more:before {
  content: "\51";
}

.icon-eh-show-less:before {
  content: "\52";
}

.icon-eh-show-details:before {
  content: "\53";
}

.icon-eh-share:before {
  content: "\54";
}

.icon-eh-set-geo-location:before {
  content: "\55";
}

.icon-eh-services:before {
  content: "\56";
}

.icon-eh-screenshot:before {
  content: "\58";
}

.icon-eh-quote:before {
  content: "\59";
}

.icon-eh-radio:before {
  content: "\5a";
}

.icon-eh-reception-1:before {
  content: "\30";
}

.icon-eh-reception-2:before {
  content: "\31";
}

.icon-eh-reception-3:before {
  content: "\32";
}

.icon-eh-reception-4:before {
  content: "\33";
}

.icon-eh-record:before {
  content: "\34";
}

.icon-eh-refresh:before {
  content: "\35";
}

.icon-eh-repair:before {
  content: "\36";
}

.icon-eh-request-change:before {
  content: "\37";
}

.icon-eh-products:before {
  content: "\38";
}

.icon-eh-processing-4-4:before {
  content: "\39";
}

.icon-eh-processing-3-4:before {
  content: "\21";
}

.icon-eh-processing-2-4:before {
  content: "\22";
}

.icon-eh-processing-1-4:before {
  content: "\23";
}

.icon-eh-priority:before {
  content: "\24";
}

.icon-eh-print:before {
  content: "\25";
}

.icon-eh-play:before {
  content: "\26";
}

.icon-eh-pipe:before {
  content: "\27";
}

.icon-eh-phone:before {
  content: "\28";
}

.icon-eh-pdf:before {
  content: "\29";
}

.icon-eh-pause:before {
  content: "\2a";
}

.icon-eh-paste:before {
  content: "\2b";
}

.icon-eh-mail:before {
  content: "\2c";
}

.icon-eh-manage-cleanups:before {
  content: "\2d";
}

.icon-eh-menu:before {
  content: "\2e";
}

.icon-eh-migrate:before {
  content: "\2f";
}

.icon-eh-minimize:before {
  content: "\3a";
}

.icon-eh-more:before {
  content: "\3b";
}

.icon-eh-movie:before {
  content: "\3c";
}

.icon-eh-mute:before {
  content: "\3d";
}

.icon-eh-nfc:before {
  content: "\3e";
}

.icon-eh-no-processing:before {
  content: "\3f";
}

.icon-eh-notification:before {
  content: "\40";
}

.icon-eh-onscreen-keyboard:before {
  content: "\5b";
}

.icon-eh-opacity:before {
  content: "\5d";
}

.icon-eh-login:before {
  content: "\5e";
}

.icon-eh-locked:before {
  content: "\5f";
}

.icon-eh-location:before {
  content: "\60";
}

.icon-eh-livelist:before {
  content: "\7b";
}

.icon-eh-list:before {
  content: "\7c";
}

.icon-eh-linkedin:before {
  content: "\7d";
}

.icon-eh-link:before {
  content: "\7e";
}

.icon-eh-is-geo-location:before {
  content: "\5c";
}

.icon-eh-info-no-circle:before {
  content: "\e000";
}

.icon-eh-info-gray:before {
  content: "\e001";
}

.icon-eh-info:before {
  content: "\e002";
}

.icon-eh-industries:before {
  content: "\e003";
}

.icon-eh-import:before {
  content: "\e004";
}

.icon-eh-grid-off:before {
  content: "\e005";
}

.icon-eh-grid-on:before {
  content: "\e006";
}

.icon-eh-grid-view:before {
  content: "\e007";
}

.icon-eh-guidance:before {
  content: "\e008";
}

.icon-eh-heartbeat:before {
  content: "\e009";
}

.icon-eh-help:before {
  content: "\e00a";
}

.icon-eh-hide-chart:before {
  content: "\e00b";
}

.icon-eh-hide-legend:before {
  content: "\e00d";
}

.icon-eh-hide-record:before {
  content: "\e00e";
}

.icon-eh-history:before {
  content: "\e00f";
}

.icon-eh-home:before {
  content: "\e010";
}

.icon-eh-image:before {
  content: "\e011";
}

.icon-eh-googleplus:before {
  content: "\e012";
}

.icon-eh-good:before {
  content: "\e013";
}

.icon-eh-forward:before {
  content: "\e014";
}

.icon-eh-folder:before {
  content: "\e015";
}

.icon-eh-flag:before {
  content: "\e016";
}

.icon-eh-filter:before {
  content: "\e017";
}

.icon-eh-favorit-filled:before {
  content: "\e018";
}

.icon-eh-favorit:before {
  content: "\e019";
}

.icon-eh-facebook:before {
  content: "\e01a";
}

.icon-eh-external-link:before {
  content: "\e01b";
}

.icon-eh-export:before {
  content: "\e01c";
}

.icon-eh-escape:before {
  content: "\e01d";
}

.icon-eh-enter:before {
  content: "\e01e";
}

.icon-eh-copy:before {
  content: "\e01f";
}

.icon-eh-csv:before {
  content: "\e020";
}

.icon-eh-cut:before {
  content: "\e021";
}

.icon-eh-database:before {
  content: "\e022";
}

.icon-eh-debug:before {
  content: "\e023";
}

.icon-eh-delete:before {
  content: "\e024";
}

.icon-eh-delivery:before {
  content: "\e025";
}

.icon-eh-diagnose:before {
  content: "\e026";
}

.icon-eh-document:before {
  content: "\e027";
}

.icon-eh-documents:before {
  content: "\e028";
}

.icon-eh-duplicate:before {
  content: "\e029";
}

.icon-eh-edit-table:before {
  content: "\e02a";
}

.icon-eh-edit:before {
  content: "\e02b";
}

.icon-eh-cad-view-front:before {
  content: "\e02c";
}

.icon-eh-cad-view-left:before {
  content: "\e02d";
}

.icon-eh-cad-view-right:before {
  content: "\e02e";
}

.icon-eh-cad-view-top:before {
  content: "\e02f";
}

.icon-eh-calendar:before {
  content: "\e030";
}

.icon-eh-camera:before {
  content: "\e031";
}

.icon-eh-cancel:before {
  content: "\e032";
}

.icon-eh-cart:before {
  content: "\e033";
}

.icon-eh-chart-position:before {
  content: "\e034";
}

.icon-eh-check:before {
  content: "\e035";
}

.icon-eh-compare:before {
  content: "\e036";
}

.icon-eh-complete:before {
  content: "\e037";
}

.icon-eh-conditions:before {
  content: "\e038";
}

.icon-eh-backward:before {
  content: "\e039";
}

.icon-eh-battery-1:before {
  content: "\e03a";
}

.icon-eh-battery-2:before {
  content: "\e03b";
}

.icon-eh-battery-3:before {
  content: "\e03c";
}

.icon-eh-battery-4:before {
  content: "\e03d";
}

.icon-eh-bluetooth:before {
  content: "\e03e";
}

.icon-eh-bookmark:before {
  content: "\e03f";
}

.icon-eh-bookmark-filled:before {
  content: "\e040";
}

.icon-eh-cable-connection-failure:before {
  content: "\e041";
}

.icon-eh-cable-connection-ok:before {
  content: "\e042";
}

.icon-eh-cad-view-bottom:before {
  content: "\e043";
}

.icon-eh-cad-view-default:before {
  content: "\e044";
}

.icon-eh-add:before {
  content: "\e045";
}

.icon-eh-add-cart:before {
  content: "\e046";
}

.icon-eh-app-settings:before {
  content: "\e047";
}

.icon-eh-application:before {
  content: "\e048";
}

.icon-eh-approval:before {
  content: "\e049";
}

.icon-eh-arrow-down:before {
  content: "\e04a";
}

.icon-eh-arrow-left:before {
  content: "\e04b";
}

.icon-eh-arrow-right:before {
  content: "\e04c";
}

.icon-eh-arrow-up:before {
  content: "\e04d";
}

.icon-eh-arrows-long-right-left:before {
  content: "\e04e";
}

.icon-eh-arrows-long-up-down:before {
  content: "\e04f";
}

.icon-eh-arrows-short-right-left:before {
  content: "\e050";
}

.icon-eh-attachment:before {
  content: "\e051";
}

.icon-eh-checkbox-checked:before {
  content: "\e052";
}

.icon-eh-checkbox-inactive:before {
  content: "\e053";
}

.icon-eh-checkbox-unchecked:before {
  content: "\e054";
}

.icon-eh-radio-button-checked:before {
  content: "\e055";
}

.icon-eh-radio-button-inactive:before {
  content: "\e056";
}

.icon-eh-radio-button-unchecked:before {
  content: "\e057";
}

.icon-eh-ticket:before {
  content: "\e058";
}

.icon-eh-docx:before {
  content: "\e059";
}

.icon-eh-jpeg:before {
  content: "\e05a";
}

.icon-eh-pptx:before {
  content: "\e05b";
}

.icon-eh-rtf:before {
  content: "\e05c";
}

.icon-eh-xml:before {
  content: "\e05d";
}

.icon-eh-xslx:before {
  content: "\e05e";
}

.icon-eh-tiff:before {
  content: "\e05f";
}

.icon-eh-zip:before {
  content: "\e060";
}

.icon-eh-minus:before {
  content: "\e061";
}

.icon-eh-confirmation:before {
  content: "\e062";
}

.icon-eh-calendar-alternative:before {
  content: "\e063";
}

.icon-eh-hide-details:before {
  content: "\e00c";
}

.icon-eh-search:before {
  content: "\57";
}

.icon-eh-lkw:before {
  content: "\e064";
}

.icon-eh-price:before {
  content: "\e065";
}

.icon-eh-my-project:before {
  content: "\e066";
}

.icon-eh-check-device-details:before {
  content: "\e067";
}

.icon-eh-return-material:before {
  content: "\e068";
}

.icon-eh-instagram:before {
  content: "\e069";
}
