@media screen and (max-width: 500px) {
  .footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-copyright a {
    margin: 10px 0 !important;
  }
}
