.description li {
    display: flex;
    flex-direction: row;
}

.description li::before {
  content: "•";
  float: left;
  display: block;
  width: 20px;
  margin-right: 10px;
  font-size: 80%;
}
