.bg-color-a {
  background-color: #c3c8cc;
}

.bg-color-b {
  background-color: #F1F2F4;
}

.bg-color-c {
  background-color: #e8e8e8;
}

.bg-color-d {
  background-color: #506671;
}

.text-color-e {
  color: #342E2A;
}